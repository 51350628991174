/* || Imports */
/* We import (1) Open Sans at 300-700 weights for regular text, (2) Kaushan Script for certain headers, and (3) material icons fonts for @mui icons */
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Material+Icons&family=Open+Sans:wght@300;400;500;700&display=swap');

/* || Main app styles */
.App {
  text-align: left;
}

.title {
  flex-grow: 1;
  text-align: center;
  font-weight: bold;
  font-family: 'Kaushan Script';
}

.progressButtonsWrapper {
  display: flex;
  margin-bottom: 32px; /* Allow the user to comfortably scroll to the bottom */
}

.backButton {
  margin-right: 8px;
}

.nextButtonWrapper {
  position: relative;
}

.nextButtonProgress {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 12px;
  margin-bottom: 12px;
}

.stepper {
  margin-top: 24px;
  margin-bottom: 24px;
}

.contentContainer {
  margin-bottom: 8px;
}

/* || Modal styles */
.modalTitle {
  text-align: center;
  font-family: 'Kaushan Script', cursive;
}

.leftColumn {
  float: left;
  width: 100%;
  padding: 1em;
  text-align: justify;
}

.rightColumn {
  display: none;
  width: 40%;
  padding: 1em;
}

@media screen and (min-width: 768px) {
  .rightColumn  { display: block; }
  .leftColumn { width: 60%; }
  #leftside > button { display: none; }
}

/* || SelectCourses Styles */
.addCourseInterface {
  display: flex;
  align-items: flex-end;
  margin-top: 8px;
  margin-bottom: 8px;
}

.autocompleteBar {
  flex: 1;
  margin-right: 8px;
}

.courseChipsStack {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
}

.courseChip {
  margin-bottom: 10px;
}

/* || SelectOffs Styles */
.periodsForm {
  margin-top: 8px;
  margin-bottom: 8px;
}

.periodsFormGroup {
  /* We want the periods form group to be centered, but curently it is not centered perfectly... we need better CSS to accomplish that. */
  margin-left: 0px;
  margin-right: 0px;
}

.requiredOffOverideForm {
  margin-left: 0px;
}

@media screen and (min-width: 600px) {
  .periodsFormGroup { margin-left: 64px; }
  .requiredOffOverideForm { margin-left: 64px; }
  .teachers { margin-left: 0px; }
}

/*|| SelectTeachers */
.teachersForm {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-left: 0px;
}

.teachersFormGroup {
  margin-right: 16px;
}

@media screen and (min-width: 600px) {
  .teachersForm { margin-left: 32px; }
}

.backdrop {
  display: flex;
  flex-direction: column;
  z-index: 2147483647;
  color: '#fff';
}

/*|| GeneratedSchedules */
.contentContainerSchedules {
  margin-bottom: 24px;
}

.tableContainer {
  margin-bottom: 16px;
}

.periodHeaderCell {
  min-width: 40px;
}

.noBorderBottom {
  border-bottom: unset;
}

.detailTable {
  margin-bottom: 16px;
}

.disclaimerText {
  color: '#888';
  font-size: 10px;
}
